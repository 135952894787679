.fallback-loader {
	position: relative;
	display: flex;
	height: 100vh;
	width: 100%;
}

.loaders {
	position: absolute;
	width: 100%;
	height: 50px;
	text-align: center;
	font-size: 10px;
	display: flex;
	justify-content: center;
	z-index: 500;
	text-shadow: 0 0 10px #fff;
	top: 50%;

	>div {
		height: 100%;
		width: 8px;
		float: left;
		margin-left: 2px;
		animation: delay 0.8s infinite ease-in-out;
		top: 50%;
	}

	.bar1 {
		background-color: #754fa0;
	}

	.bar2 {
		background-color: #09b7bf;
		animation-delay: -0.7s;
	}

	.bar3 {
		background-color: #90d36b;
		animation-delay: -0.6s;
	}

	.bar4 {
		background-color: #f2d40d;
		animation-delay: -0.5s;
	}

	.bar5 {
		background-color: #fcb12b;
		animation-delay: -0.4s;
	}

	.bar6 {
		background-color: #ed1b72;
		animation-delay: -0.3s;
	}
}

@keyframes delay {

	0%,
	40%,
	100% {
		transform: scaleY(0.05);
		-webkit-transform: scaleY(0.05);
	}

	20% {
		transform: scaleY(1);
		-webkit-transform: scaleY(1);
	}
}